import * as React from "react";
import {httpPostWithSecurityToken} from "../common/rest";
import {isValidDate} from "../common/tools";


class Bottle extends React.Component {

    constructor(props) {
        super(props);
        let contentCopy = this.props.content.slice();
        contentCopy.shift();

        this.state = {
            token: this.props.token,
            id: this.props.content[0],
            content: contentCopy,  //bottle fields minus the ID
            rowidx: this.props.rowIndex,
            edit: this.props.edit,
        }
        this.save = this.save.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.edit !== this.props.edit) {
            this.setState({
                edit: this.props.edit,
            })
        }

        if (prevProps.rowIndex !== this.props.rowIndex) {
            this.setState({
                rowidx: this.props.rowIndex,
            })
        }
    }

    save(event) {
        event.preventDefault();
        let input = event.target.firstChild;
        let orginalData = this.state.content.slice();
        let bottleData = this.state.content.slice();

        try {
            this.validateEditedColumn(input.value, this.state.edit.cell);

            bottleData[this.state.edit.cell - 1] = input.value; //-1 because the first element in the row is the Add Copy button
            this.setState({
                edit: false,
                content: bottleData
            });

            let priceFormatted = bottleData[6] === null ? null : bottleData[6].replace(",", "."); //needed so that the modelmapper can convert from String to BigDecimal

            const bottleDataToUpdate = {
                id: this.state.id,
                description: bottleData[0],
                winery: bottleData[1],
                country: bottleData[2],
                region: bottleData[3],
                grapeVariety: bottleData[4],
                vintage: bottleData[5],
                price: priceFormatted,
                created: bottleData[7]
            };

            httpPostWithSecurityToken(`/api/update-bottle`, bottleDataToUpdate, this.props.token)
                .then(response => {
                    if (response.status !== 200) {
                        console.log("ERROR");
                        this.setState({
                            content: orginalData,  //put back previous state
                        })
                    }
                })
                .catch(error => {
                    this.setState({
                        content: orginalData,  //put back previous state
                    })
                });
        } catch (err) {
            console.log("Validation failed: " + err);
        }
    }

    validateEditedColumn(value, index) {
        if (index === 6 && value != null && (isNaN(value) || value.toString().includes('.') || value.toString().includes(','))) {
            throw new Error('Vintage has to be numeric');
        }

        if (index === 7) {
            value = value.replace(",", ".");
            if (isNaN(value)) {
                throw new Error('Price has to be valid (eg 8.95)');
            }
        }

        if (index === 8) {
            if (!isValidDate(value)) {
                console.log('Not a valid date');
                throw new Error('Not a valid date');
            }
        }
    }

    render() {
        return (
            <tr key={this.state.rowidx}>
                <td style={{verticalAlign: "middle", padding: "3px"}}>
                    <a href="#" id={this.state.id} onClick={this.props.copyRow} className="button-link"><i
                        className="fa fa-plus fa-lg"/></a>
                </td>
                {this.state.content.map((cell, idx) => {
                        const edit = this.state.edit;

                        let content = cell;
                        //puts default value as content (thus cell) first, and then replaced content with the new content
                        //being the submit form with input field
                        if (edit && edit.row === this.state.rowidx && edit.cell === idx + 1) { //idx + 1 because the first element is the copy button
                            content = <form className="form-bottle" onSubmit={this.save}>
                                <input className="input-bottle" type='text' defaultValue={content} autoFocus/>
                            </form>;
                        }

                        return <td className="td-bottle" key={idx + 1}
                                   data-row={this.state.rowidx}>{content}</td>; //idx + 1 because the first element is the copy button
                    }
                )}
                <td style={{verticalAlign: "middle", padding: "5px"}}>
                    <a href="#" id={this.state.id + '-' + this.state.content[0]} onClick={this.props.drinkBottlePopUp}
                       style={{color: '#630d35'}}>
                        <i className="fa-solid fa-wine-glass-empty fa-lg"/>
                    </a>
                </td>
                <td style={{verticalAlign: "middle", padding: "5px"}}>
                    <a href="#" id={this.state.id + '-' + this.state.content[0]} onClick={this.props.deleteBottlePopUp}
                       style={{color: '#630d35'}}>
                        <i className="fa fa-trash fa-lg"/>
                    </a>
                </td>
                <td style={{verticalAlign: "middle", padding: "5px"}}>
                    <a href="#" id={this.state.id + '-' + this.state.content[0]} onClick={this.props.detailsBottlePopUp}
                       style={{color: '#630d35'}}>
                        <i className="fa fa-edit fa-lg"/>
                    </a>
                </td>
                <td><p className="height-fifteen-pixels">&nbsp;</p></td>
            </tr>
        );
    }

}

export default Bottle;